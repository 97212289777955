import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CiEdit, CiTrash } from "react-icons/ci";

export default function TableExample({ clientes, deleteCliente, updateCliente }) {
  console.log(clientes); // Ensure it's an array and not empty or undefined
  const [editingClientId, setEditingClientId] = useState(null);
  const [editedData, setEditedData] = useState({});
  
  // Handle the change for form inputs
  const handleChange = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };
  
  // Function to start editing
  const startEditing = (cliente) => {
    setEditingClientId(cliente.numerodecausa);  // Track the client being edited
    setEditedData({ ...cliente });  // Make sure you're spreading the cliente to include all fields
  };
  
  useEffect(() => {
    if (editingClientId === null) {
      console.log("Edit mode exited");
    }
  }, [editingClientId]);
  useEffect(() => {
    console.log("Current editing client ID:", editingClientId);
  }, [editingClientId]);

  // Function to stop editing and save changes
  const saveChanges = () => {
    console.log("Edited Data:", editedData); // Log the data being sent
    if (editedData) {
      updateCliente(editedData);  // Call the function passed from the parent
      setEditingClientId(null);  // Exit edit mode
      setEditedData({});  // Clear the form data
    } else {
      console.error("Missing required data for update");
    }
  };
  

  return (
    <div className="outside-table-div">
      <div className="table-div">
        <Table>
          <Thead>
            <Tr className="tabla-rows">
              <Th>Acciones</Th>
              <Th>Demandante Nombre</Th>
              <Th>Demandante Apellido</Th>
              <Th>Demandante DNI</Th>
              <Th>Demandante Domicilio</Th>
              <Th>Demandante Teléfono</Th>
              <Th>Demandante Email</Th>
              <Th>Demandante Carátula</Th>
              <Th>Nro de causa</Th>
              <Th>Demandado Nombre</Th>
              <Th>Demandado Apellido</Th>
              <Th>Demandado DNI</Th>
              <Th>Demandado Domicilio</Th>
              <Th>Demandado Teléfono</Th>
              <Th>Demandado Email</Th>
              <Th>Demandado Juzgado</Th>
              <Th>Tiene mev?</Th>
              <Th>Tiene convenio?</Th>
              <Th>Causa de</Th>
              <Th>Descripción</Th>
            </Tr>
          </Thead>
          <Tbody className="tbody-rows">
            {Array.isArray(clientes) && clientes.length > 0 ? (
              clientes.map((cliente, index) => (
                <Tr key={index}>
                  <Td>
                    {editingClientId === cliente.numerodecausa ? (
                      <>
                        <button className="actions-db" onClick={saveChanges}>
                          Save
                        </button>
                        <button
                          className="actions-db"
                          onClick={() => {
                            setEditingClientId(null);
                            setEditedData({});  // Clear edited data on cancel
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="actions-db"
                          onClick={() => startEditing(cliente)}
                        >
                          <CiEdit />
                        </button>
                        <button
                          className="actions-db"
                          onClick={() =>
                            deleteCliente(cliente.numerodecausa)
                          }
                        >
                          <CiTrash />
                        </button>
                      </>
                    )}
                  </Td>

                  {editingClientId === cliente.numerodecausa ? (
                    <>
                      <Td>
                        <input
                          type="text"
                          name="demandantenombre"
                          value={editedData.demandantenombre || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandanteapellido"
                          value={editedData.demandanteapellido || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantedni"
                          value={editedData.demandantedni || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantedomicilio"
                          value={editedData.demandantedomicilio || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantetelefono"
                          value={editedData.demandantetelefono || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandanteemail"
                          value={editedData.demandanteemail || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantecaratula"
                          value={editedData.demandantecaratula || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        {cliente.numerodecausa}
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadonombre"
                          value={editedData.demandadonombre || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadoapellido"
                          value={editedData.demandadoapellido || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadodni"
                          value={editedData.demandadodni || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadodomicilio"
                          value={editedData.demandadodomicilio || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadotelefono"
                          value={editedData.demandadotelefono || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadoemail"
                          value={editedData.demandadoemail || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadojuzgado"
                          value={editedData.demandadojuzgado || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="tieneMev"
                          checked={editedData.tieneMev || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              tieneMev: !editedData.tieneMev,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="tieneConvenio"
                          checked={editedData.tieneConvenio || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              tieneConvenio: !editedData.tieneConvenio,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="causade"
                          value={editedData.causade || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="descripcion"
                          value={editedData.descripcion || ""}
                          onChange={handleChange}
                        />
                      </Td>
                    </>
                  ) : (
                    <>
                      <Td>{cliente.demandantenombre}</Td>
                      <Td>{cliente.demandanteapellido}</Td>
                      <Td>{cliente.demandantedni}</Td>
                      <Td>{cliente.demandantedomicilio}</Td>
                      <Td>{cliente.demandantetelefono}</Td>
                      <Td>{cliente.demandanteemail}</Td>
                      <Td>{cliente.demandantecaratula}</Td>
                      <Td>{cliente.numerodecausa}</Td>
                      <Td>{cliente.demandadonombre}</Td>
                      <Td>{cliente.demandadoapellido}</Td>
                      <Td>{cliente.demandadodni}</Td>
                      <Td>{cliente.demandadodomicilio}</Td>
                      <Td>{cliente.demandadotelefono}</Td>
                      <Td>{cliente.demandadoemail}</Td>
                      <Td>{cliente.demandadojuzgado}</Td>
                      <Td>{cliente.tieneMev ? "Yes" : "No"}</Td>
                      <Td>{cliente.tieneConvenio ? "Yes" : "No"}</Td>
                      <Td>{cliente.causade}</Td>
                      <Td>{cliente.descripcion}</Td>
                    </>
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6">No clients available</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}
