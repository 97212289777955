import React, { useState, useEffect } from "react";
import "../styles/usuario.css";
import Axios from "axios";
import Table from "../components/Table"
const Usuario = () => {
  // Estados para almacenar el nombre de usuario y la contraseña introducidos
  const [username, setUsername] = useState("");
  const [passwordUsr, setPasswordUsr] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  // components for the creation of the cliente

  const [demandanteName, setDemandanteName] = useState("");
  const [demandanteLastName, setDemandanteLastName] = useState("");
  const [demandanteDni, setDemandanteDni] = useState("");
  const [demandanteDomicilio, setDemandanteDomicilio] = useState("");
  const [demandanteTelefono, setDemandanteTelefono] = useState("");
  const [demandanteEmail, setDemandanteEmail] = useState("");
  const [demandanteCaratula, setDemandanteCaratula] = useState("");
  const [numeroDeCausa, setNumeroDeCausa] = useState("");
  const [demandadoName, setDemandadoName] = useState("");
  const [demandadoLastName, setDemandadoLastName] = useState("");
  const [demandadoDni, setDemandadoDni] = useState("");
  const [demandadoDomicilio, setDemandadoDomicilio] = useState("");
  const [demandadoTelefono, setDemandadoTelefono] = useState("");
  const [demandadoEmail, setDemandadoEmail] = useState("");
  const [demandadoJuzgado, setDemandadoJuzgado] = useState("");
  const [tieneMev, setTieneMev] = useState(false);
  const [tieneConvenio, setTieneConvenio] = useState(false);
  const [causaDe, setCausaDe] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const [teamList, setTeamList] = useState([]);

  // state para manejar el contenido que se muestra (menu interno)
  const [activeSection, setActiveSection] = useState("none");


  // actualizador de IUS
  const [valorDeIUS, setValorDeIUS] = useState(0)
  const [valorDeIUSCargado, setValorDeIUSCargado] = useState(0)
  // to fetch clientes
  const [clientes, setClientes] = useState([]);
  // to delete clientes
  const [codigo, setCodigo] = useState();

  // Fetch the current value of IUS from the backend
  useEffect(() => {
    Axios.get(BACKEND_URL + '/api/valor-de-ius')
      .then(response => setValorDeIUSCargado(response.data.valorDeIUS))
      .catch(error => console.error('Error fetching the current value', error));
  }, []);

  useEffect(() => {
    console.log(clientes);
  }, [clientes]);
  // function to manage the active state of the internal system
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  // Update the value in the backend
  const updateValorDeIUS = () => {
    Axios.post(BACKEND_URL + '/api/valor-de-ius', { newValorDeIUS: parseFloat(valorDeIUS) })
      .then(response => {
        if (response.data.success) {
          setValorDeIUSCargado(response.data.valorDeIUS);
          setValorDeIUS(0); // Reset input
        }
      })
      .catch(error => console.error('Error updating the value', error));
  };

  // create cliente
  const createCliente = () => {
    Axios.post(
      BACKEND_URL + "/createeb",
      {
        demandanteName: demandanteName,
        demandanteLastName: demandanteLastName,
        demandanteDni: demandanteDni,
        demandanteDomicilio: demandanteDomicilio,
        demandanteTelefono: demandanteTelefono,
        demandanteEmail: demandanteEmail,
        demandanteCaratula: demandanteCaratula,
        numeroDeCausa: numeroDeCausa,
        demandadoName: demandadoName,
        demandadoLastName: demandadoLastName,
        demandadoDni: demandadoDni,
        demandadoDomicilio: demandadoDomicilio,
        demandadoTelefono: demandadoTelefono,
        demandadoEmail: demandadoEmail,
        demandadoJuzgado: demandadoJuzgado,
        tieneMev: tieneMev,
        tieneConvenio: tieneConvenio,
        causaDe: causaDe,
        descripcion: descripcion,
      },
      { headers: { Authorization: passwordUsr } }
    ).then((r) => {
      setTeamList([
        ...teamList,
        {
          demandanteName: demandanteName,
          demandanteLastName: demandanteLastName,
          code: r.data.code,
        },
      ]);

      document.getElementById("cliente-codigo").value = "";

      document.getElementById("name-demandante").value = "";

      document.getElementById("last-name-demandante").value = "";

      document.getElementById("dni-demandante").value = "";

      document.getElementById("domicilio-demandante").value = "";

      document.getElementById("telefono-demandante").value = "";

      document.getElementById("email-demandante").value = "";

      document.getElementById("caratula").value = "";

      document.getElementById("numero-de-causa").value = "";

      document.getElementById("name-demandado").value = "";

      document.getElementById("last-name-demandado").value = "";

      document.getElementById("dni-demandado").value = "";

      document.getElementById("domicilio-demandado").value = "";

      document.getElementById("telefono-demandado").value = "";

      document.getElementById("email-demandado").value = "";

      document.getElementById("juzgado-demandado").value = "";

      document.getElementById("tiene-mev").value = "";

      document.getElementById("tiene-convenio").value = "";

      document.getElementById("causa-de").value = "";

      document.getElementById("descripcion").value = "";


    });
  };
  //const BACKEND_URL = "https://drop-track.onrender.com";
  const BACKEND_URL = "https://estudioblasetti.onrender.com";
  //const BACKEND_URL = "http://localhost:3005/"


  // Fetch clientes data from PostgreSQL database
  const fetchClientes = () => {

    Axios.get(BACKEND_URL + "/get-clientes", {
      headers: { Authorization: passwordUsr },
    })
      .then((response) => {
        console.log(response.data);

        setClientes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //delete clientes

  const deleteCliente = (code) => {
    Axios.delete(`${BACKEND_URL}/delete-eb/${code}`, {
      headers: { Authorization: passwordUsr },
    }).then((response) => {
      setTeamList(
        teamList.filter((val) => {
          return val.code != code;
        })
      );
    });
  };
  //debug this updateClietne
  // Function to update cliente data
  const updateCliente = (editedData) => {
    console.log("Update Request Body:", editedData);

    // Check if 'data' is undefined or null
    if (!editedData) {
      console.error("Data is undefined or null");
      return;
    }


    // Destructure 'numeroDeCausa' separately from 'editedData' and rename the remaining object to 'restData'
    const { numerodecausa, ...restData } = editedData;
    const payload = { ...restData, numerodecausa }; // Use lowercase here too
    
    console.log("hola juan, test 1")
    console.log("editedData.numerodecausa")
    console.log(editedData.numerodecausa)
    console.log("hola juan, test 3")
    console.log("numeroDeCausa")
    console.log(numeroDeCausa)
    Axios.put(`${BACKEND_URL}/update-cliente`, payload, {
      headers: { Authorization: passwordUsr },
    })
      .then((response) => {
        // Ensure you're only updating the client without re-sorting the entire array
        setClientes((prevClientes) =>
          prevClientes.map((cliente) =>
            cliente.numerodecausa === editedData.numerodecausa
              ? { ...cliente, ...restData } // Spread the edited data into the existing client object
              : cliente
          )
        );
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };



  // buscar por juzgado

  const buscarPorJuzgado = () => {
    if (demandadoJuzgado) {
      Axios.get(`${BACKEND_URL}/cliente-juzgado/${demandadoJuzgado}`)
        .then((response) => {
          // Check response format
          if (Array.isArray(response.data)) {
            setClientes(response.data.flat()); // Flatten any nested arrays
          } else {
            setClientes(response.data); // If it's a flat array, set it directly
          }
        })
        .catch((error) => {
          console.error("Error fetching clients by juzgado:", error);
        });
    }
  };

  // buscar por juzgado

  const buscarPorCausaDe = () => {
    if (causaDe) {
      Axios.get(`${BACKEND_URL}/causa-de/${causaDe}`)
        .then((response) => {
          // Check response format
          if (Array.isArray(response.data)) {
            setClientes(response.data.flat()); // Flatten any nested arrays
          } else {
            setClientes(response.data); // If it's a flat array, set it directly
          }
        })
        .catch((error) => {
          console.error("Error fetching clients by causa de:", error);
        });
    }
  };

  // buscar
  const buscarConFiltros = () => {
    const queryParams = {
      juzgado: demandadoJuzgado,
      causaDe: causaDe,
      demandantenombre: demandanteName,
      demandanteapellido: demandanteLastName,
      demandanteDni: demandanteDni, // Add the demandanteDNI field
      numeroDeCausa: numeroDeCausa, // Add the numeroDeCausa field
    };

    Axios.get(`${BACKEND_URL}/clientes`, { params: queryParams })


      .then((response) => {
        if (Array.isArray(response.data.clients)) {
          setClientes(response.data.clients);
        } else {
          console.error("Expected 'clients' to be an array");
        }
      })
      .catch((error) => {
        console.error("Error fetching clients with filters:", error);
      });

  }
  const checkPassword = (e) => {
    e.preventDefault();
    if (!passwordUsr) {
      setLoginErrorMessage("Ingrese una contraseña");
      return;
    }
    Axios.post(BACKEND_URL + "/check-password-eb", { passwordUsr })
      .then((response) => {
        setLoggedIn(response.data);
        if (!response.data) {
          setLoginErrorMessage("Contraseña incorrecta");
        }
      })
      .catch((error) => {
        setLoginErrorMessage("Error al verificar la contraseña");
        console.error(error);
      });
  };

  if (!loggedIn) {
    return (
      <div className="outer-div-usuario">
        <div className="div-usuario">
          <h2>Iniciar Sesión</h2>
          <form onSubmit={checkPassword}>
            <div>
              <label htmlFor="username">Nombre de Usuario:</label>
              <br></br>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Contraseña:</label>
              <br></br>
              <input
                type="password"
                id="password"
                value={passwordUsr}
                onChange={(event) => {
                  setPasswordUsr(event.target.value);
                  setLoginErrorMessage("");
                }}
                required
              />
            </div>
            <p>{loginErrorMessage}</p>
            <button className="boton-iniciar" type="submit">
              Iniciar Sesión
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="outside-div-sistema-interno">
      <div className="inside-div-sistema-interno">

        <h1 className="titulo-sistema-interno">Sistema Interno Estudio Blasetti</h1>
        <div className="menu-navegador-sistema-interno">
          <button className="boton-navegador" onClick={() => handleButtonClick("nuevoCliente")}>Nuevo cliente</button>
          <button className="boton-navegador" onClick={() => handleButtonClick("tablaClientes")}>Tabla de clientes</button>
          <button className="boton-navegador" onClick={() => handleButtonClick("generadorRecibos")}>Generador de recibos</button>
          <button className="boton-navegador" onClick={() => handleButtonClick("valorIUS")}>Valor de IUS</button>
        </div>


        {activeSection === "tablaClientes" && (
          <div className="outside-div-seccion-table">
            {
              /*
                            <h2>Buscar Clientes por Juzgado</h2>
                          <select onChange={(e) => setDemandadoJuzgado(e.target.value)}>
                            <option value="">Seleccione un juzgado</option>
                            <option value="F1">F1</option>
                            <option value="F2">F2</option>
                            <option value="F3">F3</option>
                          </select>
                          <button onClick={buscarPorJuzgado}>Buscar por Juzgado</button>
              
                          <h2>Buscar Clientes por Causa de</h2>
                          <select onChange={(e) => setCausaDe(e.target.value)}>
                            <option value="">Seleccione dueño de causa</option>
                            <option value="ANA">ANA</option>
                            <option value="JORGE">JORGE</option>
                            <option value="MARIA">MARIA</option>
                            <option value="ALEJANDRA">ALEJANDRA</option>
                          </select>
                          <button onClick={buscarPorCausaDe}>Buscar por Dueño de causa</button>
              */
            }
            <h2>Buscar Clientes por:</h2>
            <input
              type="text"
              placeholder="Demandante nombre"
              value={demandanteName}
              onChange={(e) => setDemandanteName(e.target.value)}
            />

            <input
              type="text"
              placeholder="Demandante apellido"
              value={demandanteLastName}
              onChange={(e) => setDemandanteLastName(e.target.value)}
            />

            <input
              type="text"
              placeholder="Demandante DNI"
              value={demandanteDni}
              onChange={(e) => setDemandanteDni(e.target.value)}
            />

            <input
              type="text"
              placeholder="Numero de causa"
              value={numeroDeCausa}
              onChange={(e) => setNumeroDeCausa(e.target.value)}
            />
            <select onChange={(e) => setDemandadoJuzgado(e.target.value)}>
              <option value="">Seleccione un juzgado</option>
              <option value="F1">F1</option>
              <option value="F2">F2</option>
              <option value="F3">F3</option>
            </select>
            <select onChange={(e) => setCausaDe(e.target.value)}>
              <option value="">Seleccione dueño de causa</option>
              <option value="ANA">ANA</option>
              <option value="JORGE">JORGE</option>
              <option value="MARIA">MARIA</option>
              <option value="ALEJANDRA">ALEJANDRA</option>
            </select>

            <button onClick={buscarConFiltros}>Buscar</button>
            <br></br>
            <br></br>
            <button onClick={fetchClientes}>Ver Todos los clientes</button>


            <Table clientes={clientes} deleteCliente={deleteCliente} updateCliente={updateCliente} />
          </div>
        )
        }
        {activeSection === "valorIUS" && (


          <div className="outside-div-seccion-ius">
            <h2>Actualizador de IUS</h2>
            <div className="inside-div-seccion-ius">
              <h3>Valor en sistema: {valorDeIUSCargado}</h3>
              <input
                type="text"
                id="actualizador-ius"
                value={valorDeIUS}
                onChange={(event) => setValorDeIUS(event.target.value)}
              />
              <button onClick={updateValorDeIUS}>Actualizar IUS</button>
            </div>
          </div>
        )
        }
        {activeSection === "nuevoCliente" && (

          <div className="outside-div-seccion-nuevo-cliente">

            <h2>Cargar nuevo cliente</h2>
            <div className="inside-div-seccion-nuevo-cliente">
              <h3>Nombre demandante:</h3>
              <input
                type="text"
                id="name-demandante"
                onChange={(event) => {
                  setDemandanteName(event.target.value);
                }}
              />
              <h3>Apellido demandante:</h3>

              <input
                type="text"
                id="last-name-demandante"
                onChange={(event) => {
                  setDemandanteLastName(event.target.value);
                }}
              />

              <h3>Dni demandante :</h3>


              <input
                type="text"
                id="dni-demandante"
                onChange={(event) => {
                  setDemandanteDni(event.target.value);
                }}
              />
              <h3>Domicilio demandante:</h3>

              <input
                type="text"
                id="domicilio-demandante"
                onChange={(event) => {
                  setDemandanteDomicilio(event.target.value);
                }}
              />
              <h3>Telefono demandante:</h3>

              <input
                type="text"
                id="telefono-demandante"
                onChange={(event) => {
                  setDemandanteTelefono(event.target.value);
                }}
              />
              <h3>Email demandante:</h3>

              <input
                type="text"
                id="email-demandante"
                onChange={(event) => {
                  setDemandanteEmail(event.target.value);
                }}
              />
              <h3>Caratula demandante:</h3>

              <input
                type="text"
                id="caratula"
                onChange={(event) => {
                  setDemandanteCaratula(event.target.value);
                }}
              />
              <h3>Numero de causa:</h3>

              <input
                type="text"
                id="numero-de-causa"
                onChange={(event) => {
                  setNumeroDeCausa(event.target.value);
                }}
              />
              <h3>Nombre demandado:</h3>

              <input
                type="text"
                id="name-demandado"
                onChange={(event) => {
                  setDemandadoName(event.target.value);
                }}
              />
              <h3>Apellido demandado:</h3>

              <input
                type="text"
                id="last-name-demandado"
                onChange={(event) => {
                  setDemandadoLastName(event.target.value);
                }}
              />
              <h3>DNI demandado:</h3>

              <input
                type="text"
                id="dni-demandado"
                onChange={(event) => {
                  setDemandadoDni(event.target.value);
                }}
              />
              <h3>Domicilio demandado:</h3>

              <input
                type="text"
                id="domicilio-demandado"
                onChange={(event) => {
                  setDemandadoDomicilio(event.target.value);
                }}
              />
              <h3>Telefono demandado:</h3>

              <input
                type="text"
                id="telefono-demandado"
                onChange={(event) => {
                  setDemandadoTelefono(event.target.value);
                }}
              />
              <h3>Email demandado:</h3>

              <input
                type="text"
                id="email-demandado"
                onChange={(event) => {
                  setDemandadoEmail(event.target.value);
                }}
              />
              <h3>Juzgado demandado:</h3>

              <input
                type="text"
                id="juzgado-demandado"
                onChange={(event) => {
                  setDemandadoJuzgado(event.target.value);
                }}
              />
              <h3>Tiene mev? Bol:</h3>

              <input
                type="text"
                id="tiene-mev"
                onChange={(event) => {
                  setTieneMev(event.target.value);
                }}
              />
              <h3>Tiene convenio? Bol:</h3>

              <input
                type="text"
                id="tiene-convenio"
                onChange={(event) => {
                  setTieneConvenio(event.target.value);
                }}
              />
              <h3>Causa de:</h3>
              <input
                type="text"
                id="causa-de"
                onChange={(event) => {
                  setCausaDe(event.target.value);
                }}
              />
              <h3>Descripcion:</h3>

              <input
                type="text"
                id="descripcion"
                onChange={(event) => {
                  setDescripcion(event.target.value);
                }}
              />

              <button onClick={createCliente}>Crear cliente</button>
            </div>
          </div>

        )}

        {activeSection === "generadorRecibos" && (


          <div>
            <h1>generador de recibos</h1>
          </div>
        )}

      </div>
    </div >
  );
};
export default Usuario;
